import React, { useState } from "react";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Unordered from "~/components/List/Unordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={6}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 6</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>You probably found several things in the lemonade diet cleanse website that made you think twice about whether it is believable. You probably can think of some other ads or commercials that you have seen that also raise questions. </p>

              <p>By developing good critical thinking skills, you are better prepared to make decisions. Someone with critical thinking skills is able to do the following:</p>

              <Unordered>
                <li>understand the connections between ideas</li>
                <li>evaluate arguments</li>
                <li>detect inconsistencies and common mistakes in reasoning</li>
                <li>solve problems systematically</li>
                <li>identify the relevance and importance of ideas</li>
              </Unordered>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>This screen provides students with some additional information about the value of critical thinking skills. Ask students if they can think of a time when they used critical thinking skills to help make a decision. If appropriate, you may want to hold a discussion with students about how they can obtain more information to resolve questions they may have. For example, students could do additional internet research, talk with health professionals (such as doctors, nurses, pharmacists), or perhaps even look into primary research papers in which the original research was reported. If you wish to extend the discussion, you could ask students if they have ever heard the expression, "Don't believe everything you hear." and ask students how that might apply to what they see in the media. </p>

              <p>You could, if you wish, also extend this discussion to include the idea that some people are uncomfortable questioning an "expert." Ask students whether people should question experts and expect answers that are understandable (i.e., in language that is appropriate for everyone and not just understandable by other experts in the field). This "intimidation" is not uncommon in the medical field. Medical professionals should be able to explain issues in common language, and patients have the right to expect these types of explanations&mdash;and to keep asking questions until they understand the information. </p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
